import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './reviews.module.css'
import Layout from '../components/layout'
import IndustryPreview from '../components/industry-preview'
import SideContent from '../components/side-components/lender-side.js'

class Industry extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulIndustry.edges')
     const articles = get(this, 'props.data.allContentfulLender.edges')

    const mainContent = (
        <div>
        {/*<h2 className="section-headline text-center">Industries</h2>*/}
            <ul className="article-list p-0">
              {posts.map(({ node }) => {
                return (
                  
                    <IndustryPreview key={node.slug} article={node} />
                  
                )
              })}
            </ul>
        </div>

    );

    return (
      <Layout location={this.props.location} apply={""} mainContent={mainContent} title={"Industries"} image={require("../assets/img/landingpages/towing.jpg")} sideContent={<SideContent articles={articles} />}>
      
      </Layout>
    )
  }
}

export default Industry

export const pageQuery = graphql`
  query Industry{
    allContentfulIndustry{
      edges {
        node {
          title : name
          description {
            description
          }
          
          slug
          image {
            file {
                url
            }
          }
        }
      }
      }
      allContentfulLender(limit: 8, sort: { fields: [title], order: DESC }) {
      edges {
        node {
        tagList {
            title
          }
          title
          slug
          description {
            description
          }
          image {
            file {
                url
            }
         }
        }
      }
    }
    
  }`